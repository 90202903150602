import "./HeroStyles.css"

function Home (props) {
    return(
        <>
        <div className={props.cName}>
            <img alt="HeroImg" src={props.HeroImg}/>

            <div className="hero-text">
                <h1>{props.title}</h1>
                <p>
                    {props.text}
                </p>
                <a href={props.url} className={props.btnClass}>
                    {props.buttonText}
                </a>
            </div>
        </div>
        </>
    );
}


export default Home ;